// Normalize Styles
@import '../../node_modules/normalize.css/normalize.css';
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

// Box sizing partial styles
// Apply a natural box layout model to all elements
*,
*:before,
*:after
  -moz-box-sizing border-box
  -webkit-box-sizing border-box
  box-sizing border-box

.yeogurt-info
  text-align center
  max-width 500px
  margin-left auto
  margin-right auto

.version
  font-size 14px
  font-family Menlo, Monaco, Consolas, "Courier New", monospace
  position absolute
  bottom 10px
  right 10px

.logo
  margin-top 15px

// Styling on warning for users on IE7 or below
.browsehappy
  text-align center
  margin 0
  background #fff8e2
  color #000
  padding 0.5em 0

myfont =  'Lato', sans-serif;
back = #222
main = #f1f1f1
accent = #b999d6
myWidth = 400px

body
  padding 20px

body,html
  color main
  background-color back
  height 100%
  width 100%
  font-family myfont
  .container
    padding 20px
    max-width 700px
    display flex
    align-items center
    justify-content center
    flex-direction column
    height 100%
    @media (max-width myWidth), (max-height myWidth)
      display block
    .main
      display flex
      align-items left
      justify-content center
      flex-direction column
      flex 1
      width 100%
      .logo-flex
        display flex
        align-items center
        justify-content left
        width 100%
        .logo
          background url("../images/GravyLogoPurple.svg")
          background-size contain
          background-repeat: no-repeat;
          height 100px
          width 200px
      h1
        font-size: 40px
        line-height 60px
        @media (max-width myWidth), (max-height myWidth)
          font-size: 30px
          line-height 45px
      p
        font-size: 22px;
        a
          color main
          text-decoration none
          color accent
          &:hover
            text-decoration underline
            color darken(accent,10%)
    footer
      flex 0
      width 100%
      font-size 16px
      height 40px
      color darken(main,50)
      @media (max-width myWidth), (max-height myWidth)
        padding 30px 0 0 0
        margin 0 0 60px 0
